@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=auto');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700;900&display=swap');

@font-face {
  font-family: 'Acta Display';
  font-display: auto;
  src: url('/shared/fonts/ActaDisplay-Medium.eot') format('embedded-opentype');
  src: url('/shared/fonts/ActaDisplay-Medium.eot') format('embedded-opentype'),
    url('/shared/fonts/ActaDisplay-Medium.woff') format('woff'),
    url('/shared/fonts/ActaDisplay-Medium.woff2') format('woff2'),
    url('/shared/fonts/ActaDisplay-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Orleans';
  src: url('/shared/fonts/Orleans-Black-Web.woff2') format('woff2'),
    url('/shared/fonts/Orleans-Black-Web.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Orleans';
  src: url('/shared/fonts/Orleans-Bold-Web.woff2') format('woff2'),
    url('/shared/fonts/Orleans-Bold-Web.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Orleans';
  src: url('/shared/fonts/Orleans-Italic-Web.woff2') format('woff2'),
    url('/shared/fonts/Orleans-Italic-Web.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Orleans';
  src: url('/shared/fonts/Orleans-Roman-Web.woff2') format('woff2'),
    url('/shared/fonts/Orleans-Roman-Web.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

/* Do not edit - this file is generated */

:root {
  --fontFamilies-sans: Inter;
  --fontFamilies-serif: Orleans;
  --lineHeights-s: 1.375rem;
  --lineHeights-xs: 1rem;
  --lineHeights-normal: 1.5rem;
  --lineHeights-m: 1.75rem;
  --lineHeights-l: 2rem;
  --lineHeights-xl: 2.5rem;
  --lineHeights-xxl: 3rem;
  --lineHeights-xxxl: 3.5rem;
  --lineHeights-xxxxl: 4.5rem;
  --fontSizes-xs: 0.75rem;
  --fontSizes-s: 0.875rem;
  --fontSizes-normal: 1rem;
  --fontSizes-m: 1.25rem;
  --fontSizes-l: 1.5rem;
  --fontSizes-xl: 2rem;
  --fontSizes-xxl: 2.5rem;
  --fontSizes-xxxl: 3rem;
  --fontSizes-xxxxl: 4rem;
  --paragraphSpacing-normal: 16;
  --fontWeights-regular: 400;
  --fontWeights-semi: 600;
  --fontWeights-bold: 700;
  --fontWeights-black: 800;
  --color-blue-10: #160e5c;
  --color-blue-20: #141389;
  --color-blue-30: #1826b6;
  --color-blue-40: #1c41e4;
  --color-blue-50: #267ae7;
  --color-blue-60: #4ba6ed;
  --color-blue-70: #6fcaf2;
  --color-blue-80: #94e6f6;
  --color-blue-90: #b9f8fa;
  --color-blue-95: #dffdfa;
  --color-green-10: #0d330a;
  --color-green-20: #134f0f;
  --color-green-30: #1c7e14;
  --color-green-40: #24af19;
  --color-green-50: #2cc51b;
  --color-green-60: #4ad43d;
  --color-green-70: #7cf473;
  --color-green-80: #a8f9a2;
  --color-green-90: #d6fdd3;
  --color-green-95: #e9fde7;
  --color-mono-0: #000000;
  --color-mono-10: #191919;
  --color-mono-20: #272727;
  --color-mono-30: #383838;
  --color-mono-40: #4c4c4c;
  --color-mono-50: #616161;
  --color-mono-60: #777777;
  --color-mono-70: #8f8f8f;
  --color-mono-80: #a9a9a9;
  --color-mono-90: #c4c4c4;
  --color-mono-95: #e1e1e1;
  --color-mono-99: #f7f7f7;
  --color-mono-100: #ffffff;
  --color-red-10: #4f1112;
  --color-red-20: #721718;
  --color-red-30: #951d1e;
  --color-red-40: #b92223;
  --color-red-50: #da292b;
  --color-red-60: #e95749;
  --color-red-70: #e96d6d;
  --color-red-80: #f38483;
  --color-red-90: #f6a8a8;
  --color-red-95: #fad6d6;
  --color-teal-5: #172929;
  --color-teal-10: #213b3b;
  --color-teal-20: #385b59;
  --color-teal-30: #347e7a;
  --color-teal-40: #3aa38f;
  --color-teal-50: #41c7a4;
  --color-teal-60: #5cd7af;
  --color-teal-70: #79e5ba;
  --color-teal-80: #a9fdcf;
  --color-teal-90: #cbfde1;
  --color-teal-95: #e2fded;
  --color-yellow-10: #4c2e0d;
  --color-yellow-20: #794915;
  --color-yellow-30: #a6641c;
  --color-yellow-40: #d37f24;
  --color-yellow-50: #ed9d23;
  --color-yellow-60: #f6ad28;
  --color-yellow-70: #f9d26e;
  --color-yellow-80: #fcedb4;
  --color-yellow-90: #fdf3d2;
  --color-yellow-95: #fdf6df;

  --font-sans: var(--fontFamilies-sans), 'Open Sans', Arial, sans-serif;
  --font-serif: var(--fontFamilies-serif), Georgia, serif;
}

.mb1 {
  margin-bottom: 0.5rem;
}
.mb2 {
  margin-bottom: 1rem;
}
.mb3 {
  margin-bottom: 1.5rem;
}
.mb4 {
  margin-bottom: 2rem;
}
.mb5 {
  margin-bottom: 2.5rem;
}

.mt1 {
  margin-top: 0.5rem;
}
.mt2 {
  margin-top: 1rem;
}
.mt3 {
  margin-top: 1.5rem;
}
.mt4 {
  margin-top: 2rem;
}
.mt5 {
  margin-top: 2.5rem;
}

.ml1 {
  margin-left: 0.5rem;
}
.ml2 {
  margin-left: 1rem;
}
.ml3 {
  margin-left: 1.5rem;
}
.ml4 {
  margin-left: 2rem;
}
.ml5 {
  margin-left: 2.5rem;
}

.mr1 {
  margin-right: 0.5rem;
}
.mr2 {
  margin-right: 1rem;
}
.mr3 {
  margin-right: 1.5rem;
}
.mr4 {
  margin-right: 2rem;
}
.mr5 {
  margin-right: 2.5rem;
}

/* https://alistapart.com/article/axiomatic-css-and-lobotomized-owls */
.gap-hor-1 > * + * {
  margin-left: 0.5rem;
}
.gap-hor-2 > * + * {
  margin-left: 1rem;
}
.gap-hor-3 > * + * {
  margin-left: 1.5rem;
}
.gap-hor-4 > * + * {
  margin-left: 2rem;
}
.gap-hor-5 > * + * {
  margin-left: 2.5rem;
}

.gap-vert-1 > * + * {
  margin-top: 0.5rem;
}
.gap-vert-2 > * + * {
  margin-top: 1rem;
}
.gap-vert-3 > * + * {
  margin-top: 1.5rem;
}
.gap-vert-4 > * + * {
  margin-top: 2rem;
}
.gap-vert-5 > * + * {
  margin-top: 2.5rem;
}

